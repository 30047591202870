import React, { useMemo } from 'react'
import styled from 'styled-components'
import { isAddress } from 'utils'
import Logo from '../Logo'
import { useCombinedActiveList } from 'state/lists/hooks'
import useHttpLocations from 'hooks/useHttpLocations'
import { useActiveNetworkVersion } from 'state/application/hooks'
import { ChainId } from '@kodiak-finance/sdk-core'

export function chainIdToNetworkName(networkId: ChainId) {
  switch (networkId) {
    case ChainId.BERACHAIN_ARTIO_TESTNET:
      return 'berachain-artio'
    case ChainId.BERACHAIN_PRIVATE_TESTNET:
      return 'berachain-puer'
    default:
      return 'ethereum'
  }
}

const getTokenLogoURL = ({ symbol, chainId }: { symbol: string; chainId: ChainId }) => {
  // BERA MIGRATION
  if (symbol === '0x1E7a2161fBFcBb4d8ed9CF9B2Aef8873fB83Dd4E') {
    return 'https://bafybeibn7a54ni33nywmle5hfhuufs6dvzwnq7prtunt2jxe6cuxkeumyq.ipfs.dweb.link/'
  }
  return `https://raw.githubusercontent.com/berachain/default-token-list/main/src/assets/${symbol}.png`
}

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.text4};
`

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

export default function CurrencyLogo({
  address,
  size = '24px',
  style,
  ...rest
}: {
  address?: string
  size?: string
  style?: React.CSSProperties
}) {
  // useOptimismList()
  // const polygon = useCombinedActiveList()?.[137]

  const [activeNetwork] = useActiveNetworkVersion()
  const list = useCombinedActiveList()?.[activeNetwork.chainId]


  const checkSummed = isAddress(address)

  // console.log('tokenFromCurrencyLogo', token)

  const uri = useMemo(() => {
    if (checkSummed && list?.[checkSummed]) {
      return list?.[checkSummed].token.logoURI
    }
    return undefined
  }, [checkSummed, list])
  
  const uriLocation = useHttpLocations(uri)

  //temp until token logo issue merged
  const tempSources: { [address: string]: string } = useMemo(() => {
    return {
      ['0x4dd28568d05f09b02220b09c2cb307bfd837cb95']:
        'https://assets.coingecko.com/coins/images/18143/thumb/wCPb0b88_400x400.png?1630667954',
    }
  }, [])

  const srcs: string[] = useMemo(() => {
    const checkSummed = isAddress(address)

    if (checkSummed && address) {
      const override = tempSources[address]
      return [
        getTokenLogoURL({ symbol: checkSummed, chainId: activeNetwork.chainId }),
        ...uriLocation,

        override,
      ]
    }
    return []
  }, [address, tempSources, activeNetwork.chainId, uriLocation])

  // if (activeNetwork === OptimismNetworkInfo && address === '0x4200000000000000000000000000000000000006') {
  //   return <StyledEthereumLogo src={EthereumLogo} size={size} style={style} {...rest} />
  // }

  return <StyledLogo size={size} srcs={srcs} alt={'token logo'} style={style} {...rest} />
}
