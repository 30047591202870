import React from 'react'
import styled from 'styled-components'
import { AutoRow, RowBetween, RowFixed } from 'components/Row'
import { ExternalLink, TYPE } from 'theme'
import { useEthPrices } from 'hooks/useEthPrices'
import { formatDollarAmount } from 'utils/numbers'
import Polling from './Polling'
import { useActiveNetworkVersion } from '../../state/application/hooks'
import { SupportedNetwork } from '../../constants/networks'

const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.black};
  padding: 10px 20px;
`

const Item = styled(TYPE.main)`
  font-size: 12px;
`

const StyledLink = styled(ExternalLink)`
  font-size: 12px;
  color: ${({ theme }) => theme.text1};
`

const TopBar = () => {
  const ethPrices = useEthPrices()
  const [activeNetwork] = useActiveNetworkVersion()

  return (
    <Wrapper>
      <RowBetween>
        <Polling />
        <AutoRow gap="6px">
          <RowFixed>
            {activeNetwork.id ===
              (SupportedNetwork.BERACHAIN_PRIVATE_TESTNET || SupportedNetwork.BERACHAIN_ARTIO_TESTNET || SupportedNetwork.BERACHAIN_BARTIO_TESTNET) ? (
              <Item>Bera Price:</Item>
            ) : activeNetwork.id === SupportedNetwork.POLYGON_MUMBAI ? (
              <>
                <Item>Kodiak Price:</Item>
              </>
            ) : (
              <Item>Bera Price:</Item>
            )}
            <Item fontWeight="700" ml="4px">
              {formatDollarAmount(ethPrices?.current)}
            </Item>
          </RowFixed>
        </AutoRow>
        {/* <AutoRow gap="6px" style={{ justifyContent: 'flex-end' }}>
          <StyledLink href={process.env.REACT_APP_V2_ANALYTICS}>V2 Analytics</StyledLink> */}
        {/* <StyledLink href="https://docs.uniswap.org/">Docs</StyledLink> */}
        {/* <StyledLink href={process.env.REACT_APP_BASE_INTERFACE_URL}>App</StyledLink>
        </AutoRow> */}
      </RowBetween>
    </Wrapper>
  )
}

export default TopBar
