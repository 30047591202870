import { ApolloClient, InMemoryCache } from '@apollo/client'
import { HttpLink } from 'apollo-link-http'
import { ApolloLink } from 'apollo-link'
import { RetryLink } from 'apollo-link-retry'
import { onError } from 'apollo-link-error'

export const healthClient = new ApolloClient({
  uri: 'https://api.thegraph.com/index-node/graphql',
  cache: new InMemoryCache(),
})

export const blockClient = new ApolloClient({
  uri: 'https://api.thegraph.com/subgraphs/name/blocklytics/ethereum-blocks',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const client = new ApolloClient({
  // uri: 'https://api.thegraph.com/subgraphs/name/kodiak-finance/kodiak-v3-dev',
  uri: 'https://api.goldsky.com/api/public/project_clpx84oel0al201r78jsl0r3i/subgraphs/kodiak-v3-testnet/1.0.3/gn',
  cache: new InMemoryCache({
    typePolicies: {
      Token: {
        // Singleton types that have no identifying field can use an empty
        // array for their keyFields.
        keyFields: false,
      },
      Pool: {
        // Singleton types that have no identifying field can use an empty
        // array for their keyFields.
        keyFields: false,
      },
    },
  }),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const polygonMumbaiClient = new ApolloClient({
  uri: 'https://api.thegraph.com/subgraphs/name/kodiak-finance/kodiak-v3-dev',
  cache: new InMemoryCache({
    typePolicies: {
      Token: {
        // Singleton types that have no identifying field can use an empty
        // array for their keyFields.
        keyFields: false,
      },
      Pool: {
        // Singleton types that have no identifying field can use an empty
        // array for their keyFields.
        keyFields: false,
      },
    },
  }),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const polygonMumbaiBlockClient = new ApolloClient({
  // uri: 'https://api.thegraph.com/subgraphs/name/beracuda/mumbai-blocks',
  uri: 'https://api.thegraph.com/subgraphs/name/andreinnguyen/mumbai-block',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-first',
    },
    query: {
      fetchPolicy: 'cache-first',
      errorPolicy: 'all',
    },
  },
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    )
  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const retryLink = new RetryLink({
  delay: {
    initial: 1500,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error) => {
      console.log('retry error: ', error)
      // Check if the error is a network error
      const isNetworkError = error instanceof TypeError
      return isNetworkError
    },
  },
})

const berachainPrivateTestnetHttpLink = new HttpLink({
  uri: 'https://api.goldsky.com/api/public/project_clpx84oel0al201r78jsl0r3i/subgraphs/kodiak-v3-testnet/latest/gn',
})

// const link = ApolloLink.from([retryLink, httpLink])
const berachainPrivateTestnetLink = ApolloLink.from([errorLink, retryLink, berachainPrivateTestnetHttpLink])

export const berachainPrivateTestnetClient = new ApolloClient({
  link: berachainPrivateTestnetLink,
  cache: new InMemoryCache({
    typePolicies: {
      Token: {
        // Singleton types that have no identifying field can use an empty
        // array for their keyFields.
        keyFields: false,
      },
      Pool: {
        // Singleton types that have no identifying field can use an empty
        // array for their keyFields.
        keyFields: false,
      },
    },
  }),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const berachainPrivateTestnetBlockClient = new ApolloClient({
  // uri: 'https://api.thegraph.com/subgraphs/name/beracuda/mumbai-blocks',
  uri: 'https://api.goldsky.com/api/public/project_clpx84oel0al201r78jsl0r3i/subgraphs/berachain-testnet-blocks/1.0.0/gn',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-first',
    },
    query: {
      fetchPolicy: 'cache-first',
      errorPolicy: 'all',
    },
  },
})

// ---------Artio Testnet----------------

const berachainArtioTestnetHttpLink = new HttpLink({
  uri: 'https://api.goldsky.com/api/public/project_clpx84oel0al201r78jsl0r3i/subgraphs/kodiak-v3-berachain-public-testnet/latest/gn',
})


const berachainBartioTestnetHttpLink = new HttpLink({
  uri: 'https://api.goldsky.com/api/public/project_clpx84oel0al201r78jsl0r3i/subgraphs/kodiak-v3-berachain-bartio/latest/gn',
})

// const link = ApolloLink.from([retryLink, httpLink])
const berachainArtioTestnetLink = ApolloLink.from([errorLink, retryLink, berachainArtioTestnetHttpLink])
const berachainBartioTestnetLink = ApolloLink.from([errorLink, retryLink, berachainBartioTestnetHttpLink])

export const berachainArtioTestnetClient = new ApolloClient({
  link: berachainArtioTestnetLink,
  cache: new InMemoryCache({
    typePolicies: {
      Token: {
        // Singleton types that have no identifying field can use an empty
        // array for their keyFields.
        keyFields: false,
      },
      Pool: {
        // Singleton types that have no identifying field can use an empty
        // array for their keyFields.
        keyFields: false,
      },
    },
  }),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const berachainBartioTestnetClient = new ApolloClient({
  link: berachainBartioTestnetLink,
  cache: new InMemoryCache({
    typePolicies: {
      Token: {
        // Singleton types that have no identifying field can use an empty
        // array for their keyFields.
        keyFields: false,
      },
      Pool: {
        // Singleton types that have no identifying field can use an empty
        // array for their keyFields.
        keyFields: false,
      },
    },
  }),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const berachainArtioTestnetBlockClient = new ApolloClient({
  uri: 'https://api.goldsky.com/api/public/project_clpx84oel0al201r78jsl0r3i/subgraphs/berachain-artio-blocks/1.0.0/gn',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-first',
    },
    query: {
      fetchPolicy: 'cache-first',
      errorPolicy: 'all',
    },
  },
})

export const berachainBartioTestnetBlockClient = new ApolloClient({
  uri: 'https://api.goldsky.com/api/public/project_clpx84oel0al201r78jsl0r3i/subgraphs/bartio-blocks/1.0.0/gn',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-first',
    },
    query: {
      fetchPolicy: 'cache-first',
      errorPolicy: 'all',
    },
  },
})
