import React from 'react'
import v3 from '../../assets/images/kodiak-logo.png'
import styled, { keyframes, css } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const StyledSVG = styled.svg`
  animation: 2s ${rotate} linear infinite;
  /* height: ${({ size }) => size}; */
  /* width: ${({ size }) => size}; */
  path {
    stroke: ${({ stroke, theme }) => stroke ?? theme.primary1};
  }
`

/**
 * Takes in custom size and stroke for circle color, default to primary color as fill,
 * need ...rest for layered styles on top
 */
export default function Loader({ ...rest }: { size?: string; stroke?: string; [k: string]: any }) {
  return (
    <StyledSVG xmlns="http://www.w3.org/2000/svg" width="124" height="80" viewBox="0 0 124 80" fill="none" {...rest}>
      <path
        d="M13.8099 34.6353C13.7203 34.6353 13.6343 34.5993 13.5709 34.5353C13.5075 34.4714 13.4719 34.3846 13.4719 34.2941C13.4709 32.8381 12.8972 31.442 11.8769 30.4128C10.8566 29.3836 9.47325 28.8055 8.03083 28.8055C7.9412 28.8055 7.85524 28.7695 7.79186 28.7055C7.72848 28.6416 7.69287 28.5548 7.69287 28.4643C7.69287 28.3738 7.72848 28.2871 7.79186 28.2231C7.85524 28.1591 7.9412 28.1232 8.03083 28.1232C9.47173 28.1215 10.8531 27.543 11.872 26.5145C12.8909 25.486 13.464 24.0915 13.4657 22.637C13.4657 22.5466 13.5013 22.4598 13.5647 22.3958C13.628 22.3318 13.714 22.2959 13.8036 22.2959C13.8933 22.2959 13.9792 22.3318 14.0426 22.3958C14.106 22.4598 14.1416 22.5466 14.1416 22.637C14.1432 24.0915 14.7164 25.486 15.7352 26.5145C16.7541 27.543 18.1355 28.1215 19.5764 28.1232C19.6208 28.1232 19.6648 28.132 19.7058 28.1491C19.7468 28.1663 19.784 28.1914 19.8154 28.2231C19.8468 28.2548 19.8717 28.2924 19.8887 28.3338C19.9056 28.3752 19.9144 28.4195 19.9144 28.4643C19.9144 28.5091 19.9056 28.5535 19.8887 28.5949C19.8717 28.6363 19.8468 28.6739 19.8154 28.7055C19.784 28.7372 19.7468 28.7624 19.7058 28.7795C19.6648 28.7966 19.6208 28.8055 19.5764 28.8055C18.1355 28.8071 16.7541 29.3857 15.7352 30.4142C14.7164 31.4426 14.1432 32.8371 14.1416 34.2916C14.1423 34.3814 14.1078 34.4679 14.0457 34.5322C13.9836 34.5966 13.8989 34.6336 13.8099 34.6353Z"
        fill="white"
      />
      <path
        d="M13.81 22.6393C13.81 24.1848 14.4182 25.667 15.5009 26.7598C16.5835 27.8527 18.0518 28.4666 19.5828 28.4666C18.0518 28.4666 16.5835 29.0805 15.5009 30.1734C14.4182 31.2662 13.81 32.7484 13.81 34.2939C13.81 32.7484 13.2018 31.2662 12.1192 30.1734C11.0366 29.0805 9.56828 28.4666 8.03724 28.4666C9.56828 28.4666 11.0366 27.8527 12.1192 26.7598C13.2018 25.667 13.81 24.1848 13.81 22.6393ZM13.81 21.957C13.6308 21.957 13.4589 22.0289 13.3321 22.1569C13.2053 22.2848 13.1341 22.4584 13.1341 22.6393C13.1325 24.0033 12.5949 25.311 11.6395 26.2755C10.684 27.24 9.38851 27.7826 8.03724 27.7843C7.85798 27.7843 7.68606 27.8562 7.5593 27.9841C7.43254 28.1121 7.36133 28.2856 7.36133 28.4666C7.36133 28.6476 7.43254 28.8211 7.5593 28.9491C7.68606 29.077 7.85798 29.1489 8.03724 29.1489C9.38851 29.1506 10.684 29.6932 11.6395 30.6577C12.5949 31.6222 13.1325 32.9299 13.1341 34.2939C13.1341 34.4748 13.2053 34.6484 13.3321 34.7763C13.4589 34.9043 13.6308 34.9762 13.81 34.9762C13.9893 34.9762 14.1612 34.9043 14.288 34.7763C14.4147 34.6484 14.486 34.4748 14.486 34.2939C14.4873 32.9298 15.0247 31.6219 15.9803 30.6573C16.9358 29.6927 18.2315 29.1502 19.5828 29.1489C19.7621 29.1489 19.934 29.077 20.0608 28.9491C20.1875 28.8211 20.2588 28.6476 20.2588 28.4666C20.2588 28.2856 20.1875 28.1121 20.0608 27.9841C19.934 27.8562 19.7621 27.7843 19.5828 27.7843C18.2315 27.783 16.9358 27.2405 15.9803 26.2759C15.0247 25.3113 14.4873 24.0034 14.486 22.6393C14.486 22.4584 14.4147 22.2848 14.288 22.1569C14.1612 22.0289 13.9893 21.957 13.81 21.957Z"
        fill="white"
      />
      <path
        d="M66.4247 12.6789C66.3351 12.6789 66.2491 12.643 66.1857 12.579C66.1224 12.515 66.0868 12.4283 66.0868 12.3378C66.0854 10.8832 65.5124 9.48854 64.4935 8.45998C63.4745 7.43142 62.0929 6.85299 60.6519 6.85165C60.5623 6.85165 60.4763 6.81571 60.4129 6.75173C60.3496 6.68776 60.314 6.60098 60.314 6.5105C60.314 6.42003 60.3496 6.33326 60.4129 6.26928C60.4763 6.2053 60.5623 6.16936 60.6519 6.16936C62.093 6.16769 63.4746 5.58897 64.4935 4.56021C65.5125 3.53145 66.0854 2.13668 66.0868 0.681966C66.0868 0.637166 66.0955 0.592804 66.1125 0.551414C66.1295 0.510025 66.1544 0.472419 66.1857 0.44074C66.2171 0.409062 66.2544 0.383932 66.2954 0.366788C66.3364 0.349644 66.3803 0.34082 66.4247 0.34082C66.4691 0.34082 66.513 0.349644 66.554 0.366788C66.595 0.383932 66.6323 0.409062 66.6637 0.44074C66.6951 0.472419 66.72 0.510025 66.7369 0.551414C66.7539 0.592804 66.7627 0.637166 66.7627 0.681966C66.7643 2.1368 67.3376 3.53157 68.3567 4.56029C69.3758 5.58901 70.7575 6.16769 72.1988 6.16936C72.2884 6.16936 72.3744 6.2053 72.4377 6.26928C72.5011 6.33326 72.5367 6.42003 72.5367 6.5105C72.5367 6.60098 72.5011 6.68776 72.4377 6.75173C72.3744 6.81571 72.2884 6.85165 72.1988 6.85165C70.7576 6.85299 69.3759 7.43138 68.3568 8.4599C67.3376 9.48843 66.7643 10.8831 66.7627 12.3378C66.7627 12.4283 66.7271 12.515 66.6637 12.579C66.6003 12.643 66.5143 12.6789 66.4247 12.6789Z"
        fill="white"
      />
      <path
        d="M66.4248 0.682291C66.4246 1.44765 66.5738 2.20554 66.8639 2.91268C67.1539 3.61982 67.5791 4.26237 68.1152 4.80362C68.6512 5.34486 69.2877 5.77421 69.9881 6.06713C70.6886 6.36006 71.4394 6.51083 72.1976 6.51083C70.6665 6.51083 69.1982 7.12477 68.1156 8.2176C67.033 9.31042 66.4248 10.7926 66.4248 12.3381C66.4249 11.5728 66.2757 10.815 65.9857 10.1079C65.6956 9.40083 65.2704 8.75837 64.7343 8.21723C64.1982 7.67608 63.5618 7.24686 62.8613 6.95407C62.1609 6.66128 61.4101 6.51066 60.652 6.51083C61.4102 6.51083 62.161 6.36006 62.8614 6.06713C63.5619 5.77421 64.1983 5.34486 64.7344 4.80362C65.2705 4.26237 65.6957 3.61982 65.9857 2.91268C66.2758 2.20554 66.4249 1.44765 66.4248 0.682291ZM66.4248 0C66.2455 0 66.0736 0.0718836 65.9468 0.199838C65.8201 0.327793 65.7489 0.501336 65.7489 0.682291C65.7475 2.04653 65.2102 3.35453 64.2546 4.31931C63.2991 5.2841 62.0035 5.82687 60.652 5.82854C60.4727 5.82854 60.3008 5.90042 60.174 6.02838C60.0473 6.15633 59.9761 6.32987 59.9761 6.51083C59.9761 6.69178 60.0473 6.86533 60.174 6.99328C60.3008 7.12124 60.4727 7.19312 60.652 7.19312C62.0044 7.19279 63.3017 7.73453 64.2586 8.6993C65.2155 9.66406 65.7538 10.9729 65.7551 12.3381C65.7551 12.5191 65.8263 12.6926 65.9531 12.8206C66.0799 12.9485 66.2518 13.0204 66.431 13.0204C66.6103 13.0204 66.7822 12.9485 66.909 12.8206C67.0357 12.6926 67.107 12.5191 67.107 12.3381C67.1099 10.9762 67.6472 9.67088 68.6013 8.70784C69.5553 7.7448 70.8484 7.20244 72.1976 7.19944C72.3768 7.19944 72.5488 7.12755 72.6755 6.9996C72.8023 6.87164 72.8735 6.6981 72.8735 6.51715C72.8735 6.33619 72.8023 6.16265 72.6755 6.03469C72.5488 5.90674 72.3768 5.83486 72.1976 5.83486C70.8461 5.83319 69.5505 5.29041 68.5949 4.32563C67.6394 3.36085 67.102 2.05285 67.1007 0.688609C67.1007 0.507654 67.0295 0.33411 66.9027 0.206156C66.776 0.0782015 66.604 0.0063179 66.4248 0.0063179V0Z"
        fill="white"
      />
      <path d="M124 79.928L82.708 20.1896L70.8044 29.2337L85.3428 47.6329L45.2286 7.54696L0 79.928H124Z" fill="white" />
      <path
        d="M44.1772 55.541C44.1772 55.541 46.986 63.1903 45.0797 79.9266H53.1719C53.1719 79.9266 57.7255 64.6888 56.3399 61.7713C54.9543 58.8539 44.1772 55.541 44.1772 55.541Z"
        fill="url(#paint0_linear_168_16296)"
      />
      <path
        d="M87.9348 65.5566C83.7604 51.6998 71.2297 49.289 66.0302 49.289C63.6345 49.289 54.7863 45.448 51.5519 43.7789C51.0975 43.5451 50.4779 42.1869 50.6657 41.7093L51.5832 40.2057C51.8335 39.1633 52.0238 38.0198 51.1564 37.3995L49.6406 36.6414C49.4191 36.5287 49.169 36.4864 48.9232 36.52C48.6774 36.5536 48.4475 36.6616 48.2637 36.8296L46.8218 38.1424C46.8218 38.1424 39.582 36.5277 38.3265 36.7184C37.5918 36.8309 36.9059 38.0628 36.4628 39.0673C36.3412 39.3273 36.1297 39.5334 35.868 39.647C35.6064 39.7605 35.3126 39.7736 35.0421 39.6839C32.4624 38.7716 31.4122 39.0686 31.4122 39.0686C31.4122 39.0686 29.1466 42.0416 31.4122 44.3285C48.9546 62.523 54.9014 58.8929 57.1257 79.9985H65.1365L68.869 72.0384L75.2164 72.0953L78.2442 79.9997H90.0789L87.9348 65.5566ZM39.0625 40.61C38.9043 40.61 38.7497 40.5627 38.6182 40.474C38.4866 40.3852 38.3841 40.2591 38.3236 40.1116C38.263 39.9641 38.2472 39.8018 38.2781 39.6451C38.3089 39.4885 38.3851 39.3447 38.497 39.2317C38.6088 39.1188 38.7513 39.0419 38.9065 39.0108C39.0616 38.9796 39.2225 38.9956 39.3686 39.0567C39.5148 39.1178 39.6397 39.2213 39.7276 39.3541C39.8154 39.4869 39.8624 39.643 39.8624 39.8026C39.8624 40.0168 39.7781 40.2221 39.6281 40.3736C39.4781 40.525 39.2747 40.61 39.0625 40.61Z"
        fill="#3083DC"
      />
      <defs>
        <linearGradient
          id="paint0_linear_168_16296"
          x1="46.7432"
          y1="69.8249"
          x2="55.3137"
          y2="60.7221"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3083DC" />
          <stop offset="0.1" stopColor="#2E7FD5" />
          <stop offset="0.24" stopColor="#2A72C0" />
          <stop offset="0.41" stopColor="#235F9F" />
          <stop offset="0.61" stopColor="#194370" />
          <stop offset="0.82" stopColor="#0C2036" />
          <stop offset="1" />
        </linearGradient>
      </defs>
    </StyledSVG>
  )
}

const pulse = keyframes`
  0% { transform: scale(1); }
  60% { transform: scale(1.1); }
  100% { transform: scale(1); }
`

const Wrapper = styled.div<{ fill: number; height?: string }>`
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, fill }) => (fill ? 'black' : theme.bg0)};
  height: 100%;
  width: 100%;
  ${(props) =>
    props.fill && !props.height
      ? css`
          height: 100vh;
        `
      : css`
          height: 180px;
        `}
`

const AnimatedImg = styled.div`
  animation: ${pulse} 800ms linear infinite;
  & > * {
    width: 72px;
  }
`

export const LocalLoader = ({ fill }: { fill: boolean }) => {
  return (
    <Wrapper fill={fill ? 1 : 0}>
      <AnimatedImg>
        <img src={v3} alt="loading-icon" />
      </AnimatedImg>
    </Wrapper>
  )
}

const loadingAnimation = keyframes`
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`

export const LoadingRows = styled.div`
  display: grid;
  min-width: 75%;
  max-width: 100%;
  grid-column-gap: 0.5em;
  grid-row-gap: 0.8em;
  grid-template-columns: repeat(1, 1fr);
  & > div {
    animation: ${loadingAnimation} 1.5s infinite;
    animation-fill-mode: both;
    background: linear-gradient(
      to left,
      ${({ theme }) => theme.bg1} 25%,
      ${({ theme }) => theme.bg2} 50%,
      ${({ theme }) => theme.bg1} 75%
    );
    background-size: 400%;
    border-radius: 12px;
    height: 2.4em;
    will-change: background-position;
  }
  & > div:nth-child(4n + 1) {
    grid-column: 1 / 3;
  }
  & > div:nth-child(4n) {
    grid-column: 3 / 4;
    margin-bottom: 2em;
  }
`
