import { BigNumber } from '@ethersproject/bignumber'
import { AbstractConnector } from '@web3-react/abstract-connector'

// import { fortmatic, injected, portis, walletconnect, walletlink } from '../connectors'
import {
  injected,
  // fortmatic,
  //  portis,
  //  walletconnect
} from '../connectors'
import { SupportedNetwork } from './networks'

export const MAX_UINT128 = BigNumber.from(2).pow(128).sub(1)

export const MATIC_ADDRESS = '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270'
export const BERA_ADDRESS = '0x5806E416dA447b267cEA759358cF22Cc41FAE80F'

// const WETH_ADDRESS = '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'
const POLYGON_MUMBAI_WETH_ADDRESS = '0xadbba64729dE0fcBc142b790BF6AE434F0190606'
// const BERACHAIN_PRIVATE_TESTNET_WBERA_ADDRESS = '0x5806E416dA447b267cEA759358cF22Cc41FAE80F'
const BERACHAIN_PRIVATE_TESTNET_WETH_ADDRESS = '0x43B1db7F1C8d2bbE6290F44Bef3A0B1FbcEEcaf1'
const BERACHAIN_ARTIO_TESTNET_WETH_ADDRESS = '0x8239FBb3e3D0C2cDFd7888D8aF7701240Ac4DcA4'
const BERACHAIN_BARTIO_TESTNET_WETH_ADDRESS = '0x7507c1dc16935B82698e4C63f2746A2fCf994dF8';

export const WETH_ADDRESSES = [
  BERACHAIN_ARTIO_TESTNET_WETH_ADDRESS,
  POLYGON_MUMBAI_WETH_ADDRESS,
  BERACHAIN_PRIVATE_TESTNET_WETH_ADDRESS,
  BERACHAIN_BARTIO_TESTNET_WETH_ADDRESS
]

export const TOKEN_HIDE: { [key: string]: string[] } = {
  [SupportedNetwork.POLYGON_MUMBAI]: [],
  [SupportedNetwork.BERACHAIN_PRIVATE_TESTNET]: ['0x0000000000000000000000000000000000000001'], //BGT
  [SupportedNetwork.BERACHAIN_ARTIO_TESTNET]: [
    '0xAcD97aDBa1207dCf27d5C188455BEa8a32E80B8b', //BGT
    '0xA64BdDd2b601c2E90613c1a95c5d93ebB7501c5B', // vdHONEY
  ],

  [SupportedNetwork.BERACHAIN_BARTIO_TESTNET]: [],
}

export const POOL_HIDE: { [key: string]: string[] } = {
  [SupportedNetwork.POLYGON_MUMBAI]: [],
  [SupportedNetwork.BERACHAIN_PRIVATE_TESTNET]: [],
  [SupportedNetwork.BERACHAIN_ARTIO_TESTNET]: [],
  [SupportedNetwork.BERACHAIN_BARTIO_TESTNET]: [],
}

export const START_BLOCKS: { [key: string]: number } = {
  [SupportedNetwork.POLYGON_MUMBAI]: 38542058,
  [SupportedNetwork.BERACHAIN_PRIVATE_TESTNET]: 417752,
  [SupportedNetwork.BERACHAIN_ARTIO_TESTNET]: 304332,
  [SupportedNetwork.BERACHAIN_BARTIO_TESTNET]: 103291,
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true,
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
  },
  // WALLET_CONNECT: {
  //   connector: walletconnect,
  //   name: 'WalletConnect',
  //   iconName: 'walletConnectIcon.svg',
  //   description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
  //   href: null,
  //   color: '#4196FC',
  //   mobile: true,
  // },
  // WALLET_LINK: {
  //   connector: walletlink,
  //   name: 'Coinbase Wallet',
  //   iconName: 'coinbaseWalletIcon.svg',
  //   description: 'Use Coinbase Wallet app on mobile device',
  //   href: null,
  //   color: '#315CF5',
  // },
  COINBASE_LINK: {
    name: 'Open in Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Open in Coinbase Wallet app.',
    href: 'https://go.cb-w.com/mtUDhEZPy1',
    color: '#315CF5',
    mobile: true,
    mobileOnly: true,
  },
  // FORTMATIC: {
  //   connector: fortmatic,
  //   name: 'Fortmatic',
  //   iconName: 'fortmaticIcon.png',
  //   description: 'Login using Fortmatic hosted wallet',
  //   href: null,
  //   color: '#6748FF',
  //   mobile: true,
  // },
  // Portis: {
  //   connector: portis,
  //   name: 'Portis',
  //   iconName: 'portisIcon.png',
  //   description: 'Login using Portis hosted wallet',
  //   href: null,
  //   color: '#4A6C9B',
  //   mobile: true,
  // },
}

export const NetworkContextName = 'NETWORK'

// SDN OFAC addresses
export const BLOCKED_ADDRESSES: string[] = [
  '0x7F367cC41522cE07553e823bf3be79A889DEbe1B',
  '0xd882cFc20F52f2599D84b8e8D58C7FB62cfE344b',
  '0x901bb9583b24D97e995513C6778dc6888AB6870e',
  '0xA7e5d5A720f06526557c513402f2e6B5fA20b008',
]
