import DEFAULT_TOKEN_LIST from '@kodiak-finance/default-token-list'
export const UNSUPPORTED_LIST_URLS: string[] = []
export const OPTIMISM_LIST = 'https://static.optimism.io/optimism.tokenlist.json'
export const ARBITRUM_LIST = 'https://bridge.arbitrum.io/token-list-42161.json'
export const POLYGON_LIST =
  'https://unpkg.com/quickswap-default-token-list@1.2.2/build/quickswap-default.tokenlist.json'
export const POLYGON_MUMBAI_LIST = DEFAULT_TOKEN_LIST
export const BERACHAIN_PRIVATE_TESTNET_LIST =
  'https://raw.githubusercontent.com/berachain/default-token-list/main/src/tokens/testnet/defaultTokenList.json'

export const BERACHAIN_ARTIO_TESTNET_LIST =
  'https://gist.githubusercontent.com/beracuda/15d822c4070a26d291cce4c774d91410/raw/36ee08a447312b550157b775a8da6d167c1f8c18/berachain_artio.json'

export const BERACHAIN_BARTIO_TESTNET_LIST =
  'https://gist.githubusercontent.com/xsubject/3663b65e89396f703fc209cf47b693fa/raw/c8532100277c383e337bf1b61452b7950147fefd/bartio-tokenlist.json'

export const KODIAK_STATIC_LIST = 'https://static.kodiak.finance/tokenLists/bartio.json'

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [
  // OPTIMISM_LIST,
  // ARBITRUM_LIST,
  // POLYGON_LIST,
  BERACHAIN_PRIVATE_TESTNET_LIST,
  BERACHAIN_BARTIO_TESTNET_LIST,
  KODIAK_STATIC_LIST,
  ...UNSUPPORTED_LIST_URLS, // need to load unsupported tokens as well
]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [
  BERACHAIN_PRIVATE_TESTNET_LIST,
  BERACHAIN_ARTIO_TESTNET_LIST,
  BERACHAIN_BARTIO_TESTNET_LIST,
  KODIAK_STATIC_LIST,
]
