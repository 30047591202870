import POLYGON_LOGO_URL from '../assets/images/polygon-logo.png'
import BERACHAIN_LOGO_URL from '../assets/images/berachain-logo.png'
import { ChainId } from '@kodiak-finance/sdk-core'

export enum SupportedNetwork {
  POLYGON_MUMBAI,
  BERACHAIN_PRIVATE_TESTNET,
  BERACHAIN_ARTIO_TESTNET,
  BERACHAIN_BARTIO_TESTNET,
}

export type NetworkInfo = {
  chainId: ChainId
  id: SupportedNetwork
  route: string
  name: string
  imageURL: string
  bgColor: string
  primaryColor: string
  secondaryColor: string
}

export const PolygonMumbaiNetworkInfo: NetworkInfo = {
  chainId: ChainId.POLYGON_MUMBAI,
  id: SupportedNetwork.POLYGON_MUMBAI,
  route: 'polygon-mumbai',
  name: 'Polygon Mumbai',
  bgColor: '#0F172A',
  primaryColor: '#3083DC',
  secondaryColor: '#3083DC',
  imageURL: POLYGON_LOGO_URL,
}

export const BerachainPrivateTestnetNetworkInfo: NetworkInfo = {
  chainId: ChainId.BERACHAIN_PRIVATE_TESTNET,
  id: SupportedNetwork.BERACHAIN_PRIVATE_TESTNET,
  route: 'berachain-testnet',
  name: 'Berachain Testnet',
  bgColor: '#1E1B19',
  primaryColor: '#3083DC',
  secondaryColor: '#2172e5',
  imageURL: BERACHAIN_LOGO_URL,
}

export const BerachainArtioTestnetNetworkInfo: NetworkInfo = {
  chainId: ChainId.BERACHAIN_ARTIO_TESTNET,
  id: SupportedNetwork.BERACHAIN_ARTIO_TESTNET,
  route: 'berachain-artio',
  name: 'Berachain Artio',
  bgColor: '#1E1B19',
  primaryColor: '#3083DC',
  secondaryColor: '#2172e5',
  imageURL: BERACHAIN_LOGO_URL,
}

export const BerachainBArtioTestnetNetworkInfo: NetworkInfo = {
  chainId: ChainId.BERACHAIN_BARTIO_TESTNET,
  id: SupportedNetwork.BERACHAIN_BARTIO_TESTNET,
  route: 'berachain-bartio',
  name: 'Berachain bArtio',
  bgColor: '#1E1B19',
  primaryColor: '#3083DC',
  secondaryColor: '#2172e5',
  imageURL: BERACHAIN_LOGO_URL,
}

export const SUPPORTED_NETWORK_VERSIONS: NetworkInfo[] = [
  BerachainArtioTestnetNetworkInfo,
  BerachainBArtioTestnetNetworkInfo,
]
